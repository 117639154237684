module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-167636217-3","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"alwaysSendReferrer":true,"defer":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"218501256486877"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ariane","short_name":"Ariane","start_url":"/","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0c667a9d93e6a55ec6fc4bc3f517476e"},
    }]
